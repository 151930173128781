import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Pagination from '../components/Pagination';
import Caption from '../components/Caption';
import Split from '../components/Split';

const CabernetPage = ({ data }) => {
  return (
    <Layout>
      <Section heading="Cabernet in Australia and the creation of quintet">
        <p>
          Vines arrived in Australia with the first fleet in 1788 but details of
          the varieties and where they were planted are hard to find. I can’t
          promise that Cabernet was amongst them. One well documented fact is that
          James Busby, a British emigrant who first arrived in 1824, imported
          Cabernet grapes into NSW in 1832. This was just one more geographical
          milestone for the world’s most widely planted grape. Busby’s main focus was
          in the Hunter but during the 1830’s the industry also took off in Victoria
          (1834) and South Australia (1838) where wines made from the Cabernet family
          are now respected worldwide. Just how much of Busby’s Cabernet material
          made it south and west is not known and given how much we now know of
          ampelography (the identification and classification of grape vines) and
          how little we knew then, it may remain a mystery.
        </p>
      </Section>
      <Section background="rgb(237, 238, 238)" padded={false}>
        <div style={{ padding: '16px' }}>
          <p>
            The Yarra features very prominently in the history of Cabernet in
            Australia. Indeed, the Yarra’s journey in viticulture began around 1837
            on the site where Chateau Yering now stands. Although, as I have written
            about previously, Pinot noir was featured in the first plantings, it
            wasn’t long before Cabernet sauvignon and Cabernet franc were favoured
            by industry pioneers Paul de Castella and Samuel de Pury. The de
            Castella’s and the de Pury’s initially arrived from Switzerland with the
            intention of pastoral pursuits, mainly growing wool. Joseph Deschamps, also
            Swiss and a former vineyard manager from Neuchatel, encouraged a working
            interest in viticulture in spite of his countrymen expressing grave doubts
            about the viability of switching to wine. In fact, Hubert de Castella, who
            pursued various agricultural experiments with Guillaume de Pury, was quoted
            as saying upon his return to Switzerland “we left wool, the only
            well-beaten and safe road in Australia, in order to engage in an unknown
            and almost inextricable thicket, wine culture, in a country devoid of
            vignerons, where the only drinks are beer and alcohol (presumably meaning
            spirits)”.
          </p>
        </div>
      </Section>
      <Section>
        <p>
          Nevertheless they returned to our shores, pushed on and succeeded in
          establishing 100 acres of Cabernet at Yering with cuttings acquired from
          Chateau Lafite in Bordeaux. Before anyone assumes these vines were
          Cabernet sauvignon, the Cabernet family includes Sauvignon, Franc,
          Merlot, Malbec, Petit verdot and Petit bouschet which were all growing
          at Lafite. It is also apparent that cuttings from Yering ended up in
          other places such as Tahbilk. This material included Cabernet franc.
        </p>
        <p>
          Back in the Yarra, the growing of Cabernet was not proving trouble free
          with frosts, disease (mainly mildew), low yields, inexperience and
          marketing challenges all contributing. In spite of the difficulties by
          1878 St. Huberts had grown to 200 acres and both red and white wines
          from the Yering diaspora were famous around the world for their quality
          and favourable comparison to the best. I have written previously of how
          the industry declined from this high point to be virtually non-existent
          by 1930.
        </p>
        <Split reverse>
          <div>
            <Img fluid={data.colinImage.childImageSharp.fluid} />
            <Caption>Colin Preece</Caption>
          </div>
          <div>
            <p>
              None of this detail was lost on John Middleton. He was fascinated by the
              history of wine in the Yarra. He knew well how this valley had produced
              world class Cabernet only one short lifetime ago. Encouraged by his
              close friend and mentor, Colin Preece, manager of vineyards and cellars
              at Seppelts, Great Western, he set about reprising the Yarra’s
              production of fine Cabernet. Colin himself had previously planted
              Cabernet at Great Western and indeed, seemed to prefer these varieties
              to Hermitage, however the vines were “shy bearers” and eventually
              abandoned as being uneconomic. Years later, John himself surmised that
              fungal diseases such as Esca and Eutypa, which Cabernet is known to be
              susceptible to, may have been the cause of this vine decline. By way of the
              proceedings of the Royal Society of Victoria, John got his hands on a list
              of plantings and vintage data from the 1894 vintage at Yering. He noticed in
              particular that the grapes were all French. By this stage John, although he
              had become extremely fond of the wine romance of Burgundy, was
              well and truly obsessed with the blends of the Medoc in Bordeaux. It is
              not surprising therefore that the Quintet which owes its existence to
              John’s enduring love for the Cabernet family became Mount Mary’s
              flagship.
            </p>
          </div>
        </Split>
      </Section>
      <Section background="rgb(237, 238, 238)" padded={false}>
        <div style={{ padding: '16px' }}>
          <p>
            John was very particular about site selection and getting the right
            variety in the right location. He felt that Cabernet sauvignon, more so
            than other varieties perhaps, had a narrow ripening window and a
            tendency to be hard, green and vegetal and lacking middle palate
            intensity. I often heard him berating newcomers to viticulture about
            their casual approach to site selection. “If you plant that there, it
            will never make good wine”. He was equally critical of growers delaying
            Cabernet harvest to the point where “most of the precious Cabernet
            aroma, perfume and flavour that Bordeaux is famous for is lost”.
          </p>
          <p>
            It is not a great surprise to find that a variety taken from its cool
            northern hemisphere habitat, transported half-way around the world and
            planted into foreign soils in a harsh, hot, climate did not produce
            wines comparable to Bordeaux. It is even more astounding that growers
            persisted with delayed harvest and over ripening when even the most novice
            palate could appreciate the loss of character and quality particularly the
            loss of aroma, colour and delicacy in favour of harshness and coarseness.
          </p>
        </div>
      </Section>
      <Section>
        <Img fluid={data.grapesImage.childImageSharp.fluid} />
        <Caption>Cabernet grapes picked and on their way to the winery for processing</Caption>
        <p>
          John claimed he was the first in Australia to employ the suite of noble
          red grapes of Bordeaux in one blend. Perhaps he was right. He
          unashamedly admitted to copying the French who had proven the value of
          this form of winemaking over many generations. He also laid claim to
          pioneering the white blend of southern Bordeaux in the Yarra. It seems
          that this spirit of innovation continues in our pursuit of southern Rhone
          blends that derive from the seven varieties we planted in 2007. In the late
          1960’s John had become disenchanted with Australian wines made from 100%
          Cabernet sauvignon grapes. He rallied endlessly against high alcohol,
          overripe, heavily extracted wines with harsh, aggressive tannins. What was
          the point of making wines like these that mercilessly assault the palate?
          This was the birth of the Quintet. John was to set out on a mission to
          soften these Cabernet features by means of blending the Cabernet family,
          judicious timing of harvest, quality oak exposure and traditional egg
          white fining. John was going to produce an alternative to “fruit dead
          wines that take a generation in bottle to calm down”. He was frustrated
          and annoyed that tannic wines remained popular amongst a large section
          of the wine drinking public. John felt that the tannins and the high
          alcohol levels masked the wine faults not to mention the “macho men” who
          were too “thick” to appreciate what they were consuming.
        </p>
        <Split>
          <div>
            <p>
              Having settled on what to plant, John now had to decide where. He was
              committed to the Yarra by virtue of his medical practice and having
              spent some years with experimental plantings at what is now Chirnside
              Park, began looking for soils and aspect recognised by the early
              pioneers as being suited to viticulture. A north facing, well drained
              slope of grey sandy clay loam was what he was after. Ironically the site
              that eventually came up for auction that fitted perfectly was a property
              that had been central in the view out his kitchen window for all those
              wine-dreaming years. The rest they say is history.
            </p>
            <p>
              John’s concern about the sensitivity of Cabernet sauvignon to fungal
              trunk diseases proved to be correct and we have undergone many years of
              vine treatment, nursing and replacement. Much of the oldest Cabernet
              block (from which the JWDM was made) remains on the original roots but the
              trunks have been reconstructed from water shoots. Those of you who visit
              Mount Mary will notice the vast difference in the diameters between original
              and reconstructed trunks.
            </p>
          </div>
          <div>
            <Img fluid={data.johnImage.childImageSharp.fluid} />
            <Caption>Dr John Middleton</Caption>
          </div>
        </Split>
        <p>
          So that, in a few paragraphs, is how we grew from an ambitious and wild
          dream of John’s to a fully functioning, self-contained, family owned
          producer of an iconic Cabernet blend known as Quintet.
        </p>
        <p>
          Whilst there are now many other grapes planted and many other wines
          produced here, the Quintet will remain our flagship. There seems little
          point in arguing about which varieties are most suited in this valley,
          which make the best wines, or what will happen in the future. For us the
          enduring appeal of our dear Quintet at least proves there is one family
          of grapes that has stood the test of time in this valley and one which, by
          virtue of the blend, will be here for many vintages to come.
        </p>
      </Section>
      <Pagination
        forwardLink="/sustainability"
        backLink="/opening"
      />
    </Layout>
  );
};

CabernetPage.propTypes = {
  data: PropTypes.shape({
    colinImage: PropTypes.object,
    johnImage: PropTypes.object,
  }),
};

export const query = graphql`
  query {
    colinImage: file(relativePath: { eq: "colin-preece-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    johnImage: file(relativePath: { eq: "John.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    grapesImage: file(relativePath: { eq: "Grapes.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default CabernetPage;
